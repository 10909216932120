import data from "./sti_risk_calculator_data";
import dig from "object-dig";

class Calculator {
  get youBodyparts() {
    return document.querySelector("#you .bodypart-group");
  }

  get youSexBodypart() {
    return this.youBodyparts.querySelector(".sex");
  }

  get partnerSexes() {
    return document.querySelector("#partner .sexes");
  }

  get partnerMaleButton() {
    return this.partnerSexes.querySelector(".male");
  }

  get partnerFemaleButton() {
    return this.partnerSexes.querySelector(".female");
  }

  get partnerBodyparts() {
    return document.querySelector("#partner .bodypart-group");
  }

  get partnerSexBodypart() {
    return this.partnerBodyparts.querySelector(".sex");
  }

  get factorButtons() {
    return document.getElementById("factors");
  }

  get explanation() {
    return document.getElementById("explanation");
  }

  get peopleSlider() {
    return document.getElementById("people-slider");
  }

  get factorSlider() {
    return document.getElementById("factor-slider");
  }

  get arrow() {
    return document.getElementById("riskBar").querySelector(".arrow");
  }

  get regular() {
    return document.getElementById("regular");
  }

  get gradient() {
    return document.getElementById("gradient");
  }

  constructor() {
    this.youSex = "male";
    this.youBodypart = null;
    this.partnerSex = "male";
    this.partnerBodypart = null;
    this.factors = {};

    this.updateSexSelection();
    this.addSexButtonListeners();
    this.addBodypartButtonListeners();
    this.addFactorButtonListeners();
    this.updateRiskBar();
  }

  updateSexSelection() {
    this.inactivate([this.partnerMaleButton, this.partnerFemaleButton]);
    this.activate(this.partnerSexes.querySelector("." + this.partnerSex));
    this.hide(this.youSexBodypart.querySelectorAll("button"));
    this.show(this.youSexBodypart.querySelector("button." + this.youSex));
    this.hide(this.partnerSexBodypart.querySelectorAll("button"));
    this.show(
      this.partnerSexBodypart.querySelector("button." + this.partnerSex)
    );
  }

  inactivateBodypartSelections(who) {
    this.inactivate(this[who + "Bodyparts"].querySelectorAll("button"));
  }

  addSexButtonListeners() {
    this.partnerMaleButton.addEventListener("click", () => {
      this.updateSex("partner", "male");
    });
    this.partnerFemaleButton.addEventListener("click", () => {
      this.updateSex("partner", "female");
    });
  }

  addBodypartButtonListeners() {
    this.youBodyparts.querySelectorAll("button").forEach((button) => {
      button.addEventListener("click", () => {
        this.selectBodypart("you", button.dataset.bodypart, button);
      });
    });
    this.partnerBodyparts.querySelectorAll("button").forEach((button) => {
      button.addEventListener("click", () => {
        this.selectBodypart("partner", button.dataset.bodypart, button);
      });
    });
  }

  addFactorButtonListeners() {
    this.factorButtons.querySelectorAll("button").forEach((button) => {
      button.addEventListener("click", () => {
        this.selectFactor(button.dataset.factor, button);
      });
    });
  }

  updateSex(who, sex) {
    this[who + "Sex"] = sex;
    this.updateSexSelection();
    this.updateRiskBar();
    this.calculate();
  }

  selectBodypart(who, part, button) {
    this[who + "Bodypart"] = part;
    this.inactivateBodypartSelections(who);
    this.activate(button);
    this.updateRiskBar();
    this.calculate();
  }

  selectFactor(factor, button) {
    if (this.factors[factor]) {
      this.factors[factor] = false;
      this.inactivate([button]);
    } else {
      this.factors[factor] = true;
      this.activate(button);
    }
    this.updateRiskBar();
    this.calculate();
  }

  updateRiskBar() {
    let condom = this.factors.condom ? "condom" : "null";
    let prep = this.factors.prep ? "prep" : "null";
    let uplus = this.factors.uplus ? "uplus" : "null";
    let retrievedFactorData = dig(
      data,
      this.youBodypart,
      this.partnerBodypart,
      condom,
      prep,
      uplus
    );
    let retrievedPeopleData = dig(
      data,
      this.youBodypart,
      this.partnerBodypart,
      "null",
      "null",
      "null"
    );

    let initalState = { value: 0, explain: "" };

    if (retrievedFactorData) {
      this.factorData = retrievedFactorData;
    } else {
      this.factorData = initalState;
    }

    if (retrievedPeopleData) {
      this.peopleData = retrievedPeopleData;
    } else {
      this.peopleData = initalState;
    }

    this.setRiskBarPosition();
  }

  setRiskBarPosition() {
    var xtrmSize = 65;
    var regSize = 35;
    var limit = 1.5;
    var max = 2;
    var invisibleArrow = {
      opacity: "0",
      left: "50%",
      right: "50%"
    };
    var peopleHitLimit = this.peopleData.value >= limit;
    var factorHitLimit = this.factorData.value >= limit;
    var hitLimit = peopleHitLimit || factorHitLimit;
    var maxPosition = (value) => (value / max) * xtrmSize + regSize;
    var regularPosition = (value) => (value / limit) * 100;
    var variablePosition = (value) => (value / limit) * regSize;
    var determinePosition = (value) =>
      value >= limit ? maxPosition(value) : variablePosition(value);

    let peoplePosition;
    let factorPosition;
    if (hitLimit) {
      peoplePosition = determinePosition(this.peopleData.value);
      factorPosition = determinePosition(this.factorData.value);
    } else {
      peoplePosition = regularPosition(this.peopleData.value);
      factorPosition = regularPosition(this.factorData.value);
    }

    this.peopleSlider.setAttribute("aria-valuenow", peoplePosition);
    this.peopleSlider.style.opacity = peoplePosition ? 0.75 : 0;
    this.peopleSlider.style.left = "calc(" + peoplePosition + "% - .25em)";
    this.factorSlider.setAttribute("aria-valuenow", factorPosition);
    this.factorSlider.style.opacity = factorPosition ? 0.75 : 0;
    this.factorSlider.style.left = "calc(" + factorPosition + "% - .25em)";

    this.arrow.style.opacity = factorPosition ? 0.75 : 0;
    let smaller = factorPosition < peoplePosition;
    let position = factorPosition / peoplePosition;
    let leftPos = Math.min(factorPosition, peoplePosition);
    let rightPos = Math.max(factorPosition, peoplePosition);
    this.arrow.style.opacity = factorPosition ? 0.75 : 0;
    this.arrow.style.left = `calc(${leftPos}% + ${smaller ? 1 : 0}em)`;
    this.arrow.style.right = `calc(100% - ${rightPos}% + ${smaller ? 0 : 1}em)`;

    if (position < 1) {
      this.arrow.classList.remove("right");
      this.arrow.classList.add("left");
    } else if (position > 1) {
      this.arrow.classList.remove("left");
      this.arrow.classList.add("right");
    } else {
      this.arrow.style = invisibleArrow;
    }

    let fullBar = peopleHitLimit || factorHitLimit;
    if (fullBar) {
      this.regular.classList.add("fullbar");
      this.gradient.classList.add("fullbar");
    } else {
      this.regular.classList.remove("fullbar");
      this.gradient.classList.remove("fullbar");
    }
  }

  activate(element) {
    element.classList.add("active");
  }

  inactivate(elements) {
    elements.forEach((element) => {
      element.classList.remove("active");
    });
  }

  show(element) {
    element.style.display = "initial";
  }

  hide(elements) {
    elements.forEach((element) => {
      element.style.display = "none";
    });
  }

  calculate() {
    let startText = "Pick a combination to get started.";
    this.explanation.innerHTML =
      this.factorData.explain || this.peopleData.explain || startText;
  }
}

if (document.getElementById("sti-risk-calculator-tool")) {
  new Calculator();
}
